<template>
  <div class="contact-list">
    <div class="select-box" v-if="selectCount > 0">
      <div class="icon-box" @click="clear">
        <i class="bi bi-x-lg"></i>
      </div>
      <span>{{ selectCount }} selected</span>
      <div @click.stop="mutilSend" class="icon-box">
        <i class="bi bi-chat-square-dots"></i>
      </div>
      <div @click.stop="deleteSelect" class="icon-box">
        <i class="bi bi-trash3"></i>
      </div>
    </div>
    <div @click="changeContact(item)" :class="`contact-box ${item.selected?'user-selected':''} ${selectContact && item.phone === selectContact.phone?'user-focused':''}`" v-for="(item, index) in contacts" :key="index">
      <div class="left">
        <el-badge is-dot class="item" :hidden="item.unread<=0">
          <div class="icon-box" @click.stop="selectedChange(index)" :style="`background-color: ${item.color}`">
            <i class="bi bi-person-fill"></i>
            <i class="bi bi-check2"></i>
          </div>
        </el-badge>
      </div>
      <div class="center">
        <p class="phone-number">{{ item.phone }}</p>
        <p class="last-message">{{ item.lastMessage }}</p>
      </div>
      <div class="right">
        <p class="time">{{ getDay(item.time) }}</p>
        <p class="time">{{ getTime(item.time) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { stringToColor } from '@/utils/color'
import { deleteUnreadMessage } from '@/api/message.js'
import { clearData, selectContact, insertMessage, removeUnreadMessage } from '@/utils/indexdb'
export default {
  name: 'contact',
  props: {
    contacts: Array,
    index: Number
  },
  data() {
    return {
      selectCount: 0
    }
  },
  computed: {
    ...mapGetters([
      'selectContact',
      'fromPhone',
      'unread',
      'unreadMessage'
    ])
  },
  methods: {
    getDay(time) {
      const index = time.indexOf(' ')
      return time.substr(0, index)
    },
    getTime(time) {
      const index = time.indexOf(' ')
      return time.substr(index + 1)
    },
    clear() {
      for (let i = 0; i < this.contacts.length; i++) {
        const element = this.contacts[i];
        element.selected  = false
      }
      this.selectCount = 0
    },
    mutilSend() {
      const selectContacts = []
      for (let i = 0; i < this.contacts.length; i++) {
        const contact = this.contacts[i];
        if (contact.selected) {
          selectContacts.push(contact.phone)
        }
      }
      this.$store.dispatch('phone/setMutilPhones', selectContacts)
      this.selectCount = 0
      const contacts = selectContact(this.fromPhone.fullNumber)
      this.$store.dispatch('sms/SetContact', contacts)
      this.clear()
    },
    deleteSelect() {
      this.$alert(`Do you want to delete select contact all data?`, 'Tip', {
        confirmButtonText: 'Yes',
        callback: (action) => {
          if ('confirm' !== action) {
            return
          }
          const selectContacts = []
          for (let i = 0; i < this.contacts.length; i++) {
            const contact = this.contacts[i];
            if (contact.selected) {
              selectContacts.push(contact)
            }
          }
          for (let i = 0; i < selectContacts.length; i++) {
            const element = selectContacts[i];
            clearData(this.fromPhone.fullNumber, element.phone)
          }
          const contacts = selectContact(this.fromPhone.fullNumber)
          this.$store.dispatch('sms/SetContact', contacts)
          this.$store.dispatch('sms/UpdateSelectContact', null)
          this.$store.dispatch('sms/RemoveLastMessages', selectContacts)
          this.selectCount = 0
        }
      })
    },
    async changeContact(item) {
      if (this.selectContact && this.selectContact.phone === item.phone) {
        return
      }
      const unNeedAaddLocal = []
      const needAddLocal = []
      const deleteIdList = []
      const deleteLocalUnreadMessage = []
      for (let i = 0; i < this.unreadMessage.length; i++) {
        const message = this.unreadMessage[i]
        if (message.to === item.phone && message.from === this.fromPhone.fullNumber) {
          if (message.ws) {
            deleteLocalUnreadMessage.push(message)
          } else {
            deleteIdList.push(message.messageId)
          }
          needAddLocal.push(message)
        } else {
          unNeedAaddLocal.push(message)
        }
      }
      if (deleteIdList && deleteIdList.length > 0) {
        // delete unread message
        try {
          await deleteUnreadMessage({
            idList: deleteIdList
          })
        } catch (e) {
          this.$message.error(e)
        }
      }
      if (deleteLocalUnreadMessage && deleteLocalUnreadMessage.length > 0) {
        removeUnreadMessage(deleteLocalUnreadMessage)
      }
      this.addMsgToLocal(needAddLocal, item.phone)
      this.$store.dispatch('sms/SetUnreadMessage', unNeedAaddLocal)
      this.$store.dispatch('sms/UpdateSelectContact', item)
    },
    addMsgToLocal(needAddLocal, phone) {
      for (let i = 0; i < needAddLocal.length; i++) {
        const message = needAddLocal[i]
        const { from, to, body, messageId, time } = message
        if (to === phone) {
          insertMessage(from, to, {
            body,
            from,
            to,
            messageId,
            time,
            isLeft: true,
            color: stringToColor(to)
          })
        }
      }
    },
    // 修改选中的状态
    selectedChange(index) {
      this.contacts[index].selected = !this.contacts[index].selected
      let count = 0
      for (let i = 0; i < this.contacts.length; i++) {
        const element = this.contacts[i];
        if (element.selected) {
          count++
        }
      }
      this.selectCount = count
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.contact-list {
  overflow-y: auto;
  flex: 1;
  scroll-behavior: smooth;
}
.contact-box:hover {
  background-color: #f8f9fa;
}

.user-focused {
  background-color: #eaeaea !important;
}

.user-selected {
  background-color: #f1f3f4 !important;
  .bi-check2 {
    background: #00796b !important;
    display: inline-block !important;
  }
}

.user-selected.contact-box::before {
    left: 2.22%;
    width: 97.78%;
}
.contact-box::before {
  background-color: #e0f2f1;
  border-bottom-left-radius: 32px;
  border-top-left-radius: 32px;
  content: " ";
  left: 100%;
  width: 0;
  height: 81.26%;
  position: absolute;
  top: 9.37%;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}
.contact-box {
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  .el-badge__content.is-fixed.is-dot {
    top: 6px;
    right: 26px;
  }
  .center {
    flex: 1;
    z-index: 1000;
    text-overflow: ellipsis;
    overflow: hidden;
    p {
      margin: 0px;
      max-width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    .phone-number {
      color: #202124;
      letter-spacing: .2px;
      font: 400 14px/20px Roboto,"Helvetica Neue",sans-serif;
    }
    .last-message {
      color: #5f6368;
      font: 400 14px/20px Roboto,"Helvetica Neue",sans-serif;
      letter-spacing: .2px;
    }
  }
  .icon-box {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;
    position: relative;
    .bi {
      font-size: 26px;
    }
    .bi-check2 {
      display: none;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: 50%;
      background: rgba(60,64,67,.6);
    }
  }
  .icon-box:hover {
    .bi-check2 {
      display: inline-block;
      z-index: 10000;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    .unit {
      font-size: 8px;
      margin-left: 2px;
      font-weight: bold;
    }
    .limit-message {
      text-align: right;
      margin: 0 18px;
      .send {
        color: #189a66;
        font-size: 10px;
        font-weight: bold;
        margin: 0 2px;
        span {
          margin: 0 2px;
        }
      }
      .limit {
        color: #f92f46 !important;
      }
    }
    
    .time {
      font: 400 12px/16px Roboto,"Helvetica Neue",sans-serif;
      letter-spacing: .3px;
      margin: 0px 18px;
      text-align: right;
    }
  }
}
</style>
    