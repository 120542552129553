<template>
  <div class="archive-box">
    <contact-list :contacts="contacts" />
  </div>
</template>

<script>
import ContactList from '@/components/ContactList/index'
import { mapGetters } from 'vuex'

export default {
  name: 'archive',
  components: {
    ContactList
  },
  computed: {
    ...mapGetters([
      'contacts'
    ])
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.archive-box {

}
</style>
  